<script setup>
const { $tracking } = useNuxtApp();

const { useUrl } = useUrls();

const logo = {
  desktop: 'https://a.hwstatic.com/image/upload/h_40,w_169,q_50/v1651737477/pwa/roamies/roamies.logo.white.png',
  mobile: 'https://a.hwstatic.com/image/upload/h_32,w_135,q_50/v1651737477/pwa/roamies/roamies.logo.white.png',
};

const images = [
  {
    desktop: 'https://a.hwstatic.com/image/upload/f_auto,h_260,w_355,q_50/v1651737425/pwa/roamies/roamies.d.2.png',
    desktopWidth: 355,
    desktopHeight: 260,
    mobile: 'https://a.hwstatic.com/image/upload/f_auto,h_150,w_172,q_50/v1651737423/pwa/roamies/roamies.m2.png',
    mobileWidth: 172,
    mobileHeight: 150,
  },
  {
    desktop: 'https://a.hwstatic.com/image/upload/f_auto,h_170,w_170,q_50/v1651737423/pwa/roamies/roamies.d.3.png',
    desktopWidth: 170,
    desktopHeight: 170,
    mobile: 'https://a.hwstatic.com/image/upload/f_auto,h_150,w_120,q_50/v1651737423/pwa/roamies/roamies.m3.png',
    mobileWidth: 120,
    mobileHeight: 150,
  },
  {
    desktop: 'https://a.hwstatic.com/image/upload/f_auto,h_302,w_287,q_50/v1651737425/pwa/roamies/roamies.d.1.png',
    desktopWidth: 287,
    desktopHeight: 302,
    mobile: 'https://a.hwstatic.com/image/upload/f_auto,h_173,w_135,q_50/v1651737423/pwa/roamies/roamies.m1.png',
    mobileWidth: 135,
    mobileHeight: 173,
  },
  {
    desktop: 'https://a.hwstatic.com/image/upload/f_auto,h_292,w_393,q_50/v1651737423/pwa/roamies/roamies.d.4.png',
    desktopWidth: 393,
    desktopHeight: 292,
    mobile: 'https://a.hwstatic.com/image/upload/f_auto,h_181,w_180,q_50/v1651737423/pwa/roamies/roamies.m4.png',
    mobileWidth: 180,
    mobileHeight: 181,
  },
];

function roamiesTracking() {
  $tracking.onRoamiesSectionInteraction();
}
</script>

<template>
  <section class="roamies-section-wrapper">
    <div class="text">
      <img
        :src="logo.desktop"
        alt="Roamies - Hostelworld + G Adventures"
        class="text-logo text-desktop"
        height="40"
        loading="lazy"
        width="169"
      />
      <img
        :src="logo.mobile"
        alt="Roamies - Hostelworld + G Adventures"
        class="text-logo text-mobile"
        height="32"
        loading="lazy"
        width="135"
      />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <h2
        class="text-header"
        v-html="$t('t_ROAMIESSECTIONHEADER')"
      />
      <p class="text-body">
        {{ $t('t_ROAMIESSECTIONBODY') }}
      </p>
      <a
        :href="useUrl.getGenericPageUrl('roamies')"
        class="text-button"
        rel="noopener"
        @click="roamiesTracking()"
      >
        {{ $t('t_SHOWME') }}
      </a>
    </div>
    <div class="mosaic">
      <div class="mosaic-first-column">
        <template
          v-for="(image, index) in images.slice(0, 2)"
          :key="image.desktop"
        >
          <img
            :alt="`Roamies image ${index + 1}`"
            :class="{ 'first-image': index === 0, 'last-image': index !== 0 }"
            :height="image.desktopHeight"
            :src="image.desktop"
            :width="image.desktopWidth"
            class="mosaic-image desktop-image"
            loading="lazy"
          />
          <img
            :alt="`Roamies image ${index + 1}`"
            :class="{ 'first-image': index === 0, 'last-image': index !== 0 }"
            :height="image.mobileHeight"
            :src="image.mobile"
            :width="image.mobileWidth"
            class="mosaic-image mobile-image"
            loading="lazy"
          />
        </template>
      </div>
      <div class="mosaic-second-column">
        <template
          v-for="(image, index) in images.slice(2, 4)"
          :key="image.desktop"
        >
          <img
            :alt="`Roamies image ${index + 1}`"
            :class="{ 'first-image': index === 0, 'last-image': index !== 0 }"
            :height="image.desktopHeight"
            :src="image.desktop"
            :width="image.desktopWidth"
            class="mosaic-image desktop-image"
            loading="lazy"
          />
          <img
            :alt="`Roamies image ${index + 1}`"
            :class="{ 'first-image': index === 0, 'last-image': index !== 0 }"
            :height="image.mobileHeight"
            :src="image.mobile"
            :width="image.mobileWidth"
            class="mosaic-image mobile-image"
            loading="lazy"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.roamies-section-wrapper {
  align-items: center;
  background-color: $wds-color-ink-darker;
  clip-path: polygon(0 calc(100% - #{$wds-spacing-xxl}), 0 #{$wds-spacing-xxl}, 100% 0, 100% 100%);
  display: flex;
  flex-direction: column-reverse;
  min-height: wds-rem(800px);
  justify-content: center;
  padding: 0 $wds-spacing-m;

  @include desktop-large {
    flex-direction: row;
    padding: 0 calc(2 * #{$wds-spacing-xxxl});
  }

  .text {
    color: $wds-color-white;
    margin-bottom: calc(3 * #{$wds-spacing-xxxl});
    text-align: center;

    .text-desktop {
      display: none;
    }

    .text-mobile {
      display: initial;
    }

    @include desktop {
      .text-desktop {
        display: initial;
      }

      .text-mobile {
        display: none;
      }
    }

    @include desktop-large {
      max-width: wds-rem(582px);
      margin-bottom: 0;
      margin-right: wds-rem(110px);
      text-align: left;
    }

    &-logo,
    &-header,
    &-body {
      margin-bottom: $wds-spacing-m;
    }

    &-header {
      @include title-1-bld;

      font-size: wds-rem(40px);
      line-height: wds-rem(48px);
      font-weight: 700;

      @include desktop-large {
        font-size: wds-rem(64px);
        line-height: wds-rem(72px);
      }

      :deep(span) {
        color: $wds-color-pink;
      }
    }

    &-body {
      @include title-6-reg;
    }

    &-button {
      background-color: $wds-color-pink;
      border: none;
      border-radius: $wds-border-radius-m;
      color: $wds-color-white;
      cursor: pointer;
      font-family: $font-family-inter;
      font-weight: 700;
      font-size: $wds-font-size-body-1;
      line-height: $wds-line-height-title-6;
      padding: calc(#{$wds-spacing-l} / 2) $wds-spacing-l;
      text-decoration: none;
      transition: background 0.15s ease-in-out;

      &:hover {
        background-color: $wds-color-pink-dark;
      }
    }
  }

  .mosaic {
    display: flex;
    align-items: center;
    margin-bottom: $wds-spacing-xl;
    margin-top: calc(#{$wds-spacing-m} + #{$wds-spacing-xxl});
    max-height: wds-rem(374px);
    max-width: wds-rem(343px);

    .mosaic-image.desktop-image {
      display: none;
    }

    .mosaic-image.mobile-image {
      display: block;
    }

    @include desktop-large {
      margin: calc(2 * #{$wds-spacing-xxl}) 0;
      max-height: wds-rem(620px);
      max-width: wds-rem(780px);
    }

    @include desktop {
      .mosaic-image.desktop-image {
        display: block;
      }

      .mosaic-image.mobile-image {
        display: none;
      }
    }

    &-first-column {
      margin-right: -$wds-spacing-m;

      @include desktop-large {
        margin-right: $wds-spacing-l;
        margin-top: $wds-spacing-xxxl;
      }

      .mosaic-image.last-image {
        margin-left: auto;
        margin-right: $wds-spacing-xl;

        @include desktop-large {
          margin-right: 0;
        }
      }
    }

    &-last-column {
      .mosaic-image.first-image {
        @include desktop-large {
          margin-left: 0;
        }
      }
    }

    .mosaic-image.first-image {
      margin-bottom: $wds-spacing-m;

      @include desktop-large {
        margin-bottom: $wds-spacing-l;
      }
    }

    &-image {
      border-radius: $wds-border-radius-xl;
      display: block;
    }
  }
}
</style>
